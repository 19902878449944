import { initializeSessionManager } from './SessionManager';

export const reloadPageWithLogin = (userPhoneNumber) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userPhoneNumber', userPhoneNumber);
    initializeSessionManager(); // セッションタイマーを初期化
    window.location.reload();
};

export const checkLoginStatus = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const userPhoneNumber = localStorage.getItem('userPhoneNumber');
    if (isLoggedIn) {
        initializeSessionManager(); // ログイン状態の場合、セッションタイマーを初期化
    }
    return { isLoggedIn, userPhoneNumber };
};