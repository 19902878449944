import Cookies from 'js-cookie';

export const getBrowserId = () => {
    let browserId;

    // Try to use localStorage
    try {
        browserId = localStorage.getItem('browserId');
        if (!browserId) {
            browserId = generateBrowserId();
            localStorage.setItem('browserId', browserId);
        }
    } catch (e) {
        // If localStorage is not available, use Cookies
        browserId = Cookies.get('browserId');
        if (!browserId) {
            browserId = generateBrowserId();
            Cookies.set('browserId', browserId, { expires: 365 }); // Set cookie to expire in 1 year
        }
    }

    return browserId;
};

const generateBrowserId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
};