import { logout } from './Logout';

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 mins to logout

const resetActivity = () => {
    localStorage.setItem('lastActivity', Date.now().toString());
};

const checkSession = () => {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity && Date.now() - parseInt(lastActivity) > SESSION_TIMEOUT) {
        logout();
    }
};

export const initializeSessionManager = () => {
    const eventHandler = () => {
        resetActivity();
        checkSession();
    };

    ['mousedown', 'keydown', 'scroll', 'touchstart'].forEach(eventType => {
        document.addEventListener(eventType, eventHandler);
    });

    resetActivity();
    setInterval(checkSession, 5 * 60 * 1000); // Check every 5 minutes
};

export const clearSessionTimeout = () => {
    localStorage.removeItem('lastActivity');
};